import React from 'react'
import { ProductType } from "../../types"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import SimpleProduct from '../components/Products/SimpleProduct/SimpleProduct'
import { MAGENTO_URL } from '../../userglobals'
import { graphql } from "gatsby"

type SimpleProductTemplateType = {
  pageContext: {
    product: ProductType
    lastmoddate: String
  }
  location: Location
  data: any
}

const simpleProductTemplate: React.FC<SimpleProductTemplateType> = ({
  pageContext: { product, lastmoddate },
  location,
  data
}) => {
  var metaTitle = "";
  var metaDescription = "";
  var metaKeyword = "";
  const { site } = data;

  const schema = {
    "@context": "https://schema.org/", 
    "@type": "Product", 
    "name": product.name,
    "image": MAGENTO_URL + "media/catalog/product" + product.image,
    "description": product?.custom_attributes?.find(x => x.attribute_code === 'description')?.value,
    "brand": "Virtual Joias",
    "sku": product.sku,
    "offers": {
      "@type": "Offer",
      "url": site.siteMetadata.siteUrl + "/" + product.slug,
      "priceCurrency": "BRL",
      "price": product.price
    }
  }
  
  product.custom_attributes.forEach(x => {
    switch (x.attribute_code) {
      case "meta_title": {
        metaTitle = x.value
        break
      }
      case "meta_description": {
        metaDescription = x.value
        break
      }
      case "meta_keyword": {
        metaKeyword = x.value
        break
      }
      default: {
        break
      }
    }
  })

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeyword}
        schemaMarkup={schema}
      />
      <SimpleProduct product={product} />
    </Layout>
  )
}


export default simpleProductTemplate

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`