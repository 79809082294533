import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { MICROSERVICE_WISHLIST } from '../../../../userglobals'
import {
   addToWishlist,
   removeFromWishlist
} from '../../../store/actions'
import {
   ButtonAddToCartSimple,
   ButtonAddToWishlist,
   ButtonShare,
   CustomOptions,
   IncludedItens,
   ProductDetails,
   ProductImagesCarousel,
   ProductName,
   ProductPrice,
   ProductReviews,
   ProductSpecifications,
   Sizes,
   Tips
} from '../components'

type SimpleProductType = {
   product: any
}

const SimpleProduct: React.FC<SimpleProductType> = ({ product }) => {
   const [checked, setChecked] = useState(false);
   const [customOptions, setCustomOptions] = useState([]);
   const wishlist = useSelector((state: any) => state.wishlist);
   const userAuth = useSelector((state: any) => state.userAuth);
   console.log(product)

   const handleCustomOptionChange = (label: any, value: any, id: number) => {
      const newCustomOptions = [...customOptions];
      const indexCustom = newCustomOptions.findIndex(x => x.label === label);
      if (indexCustom === -1) {
         newCustomOptions.push({ label: label, value: value, id: id });
      } else {
         newCustomOptions[indexCustom].value = value;
      }
      setCustomOptions(newCustomOptions)
   }

   useEffect(() => {
      const alreadyChecked = wishlist.some((x: any) => x.product_id === product.id.toString());
      const alreadyChecked2 = wishlist?.some((x: any) => x=== product.id);
      if (alreadyChecked || alreadyChecked2) {
         setChecked(true)
      }
   }, [])

   const toggleCheckbox = () => {
      if (!checked) {
         setChecked(true)
         addToWishlist(product.id)
         if (userAuth.auth === true) {
            axios.post(MICROSERVICE_WISHLIST + "add-wishlist", { ...userAuth, productId: product.id })
            // .then(res=>console.log(res.data))
         }
      } else {
         setChecked(false)
         removeFromWishlist(product.id)
         if (userAuth.auth === true) {
            axios.post(MICROSERVICE_WISHLIST + "delete-wishlist", { ...userAuth, productId: product.id })
            // .then(res=>console.log(res.data))
         }
      }
   };

   const productImagesUrls = product.media_gallery_entries.map((item: any) => item.file);

   return (
      <div style={{ backgroundColor: '#fff', display: 'flex', flexWrap: 'wrap' }}>
         <div style={{ width: '44.5%', borderRight: '2px solid #f8f7f6' }}>
            <ProductImagesCarousel productImagesUrls={productImagesUrls} />
            <ProductDetails customAttributes={product.custom_attributes} />
            <IncludedItens customAttributes={product.custom_attributes} />
            <Tips customAttributes={product.custom_attributes} />
         </div>
         <div style={{ width: '55.5%' }}>
            <ProductName productName={product.name} sku={product.sku} product={product} checked={checked} toggleCheckbox={toggleCheckbox} />
            {product.product_template !== '1' ?
               (product.product_template === '2' || product.product_template === '3' || product.product_template === '7' || product.product_template === '8' ?
                  <>
                     <Sizes />
                     <CustomOptions customOptions={product.options} template={product.product_template} handleCustomOptionChange={handleCustomOptionChange} customOptionsState={customOptions} />
                  </> :
                  <CustomOptions customOptions={product.options} template={product.product_template} handleCustomOptionChange={handleCustomOptionChange} customOptionsState={customOptions} />) : null
            }
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
               <ProductPrice product={product} />
               <ButtonAddToCartSimple simpleProduct={product} customOptions={customOptions} />
            </div>
            <div style={{ borderTop: '2px solid #f8f7f6', display: 'flex', flexWrap: 'wrap' }}>
               <ButtonAddToWishlist checked={checked} toggleCheckbox={toggleCheckbox} />
               <ButtonShare />
            </div>
            <ProductSpecifications customAttributes={product.custom_attributes} />
         </div>
         <div style={{ width: '100%', borderTop: '2px solid #f8f7f6', padding: '15px' }}>
            <ProductReviews />
         </div>
      </div>
   )
}

export default SimpleProduct
